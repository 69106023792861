.datepicker {
    width: 100%;
  }
  .myContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    flex: 1 1 0px;
  }
  
  .myDatePicker {
    width: 100%;
    height: 48px;
    padding: 8px 48px 8px 16px;
    font-size: 100%;
  }
  
  #tooltipbar{
    display: inline-flex;
    margin-left: 12px;
  }