.business-address-heading-container{
    display: flex;
  }
  
  h5.business-address-heading {
      margin-bottom: 1rem;
      color: rgb(73, 22, 109);
      font-size: 1.2rem;
  }
  
  .business-address-fields-container {
    margin-top: 1.5rem;
    padding: 2rem;
    background-color: rgb(242, 239, 244);
    border: 1px solid rgb(216, 216, 216);
  }
  
  .add-info:hover + .add-info:hover {
    margin-top: 20px;
  }
  .add-info-hover {
    display: none;
    margin-top: 20px;
  }
  
  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    /* visibility: hidden; */
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }